<template>
    <div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
     <div v-if="loading">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
    <b-card v-else >
        <!-- input search -->
        <div class="custom-search">
        <b-row>
            
            <b-col md="3" >
             <b-form-group>
                <b-button
                variant="primary"
                :to="{ name: 'fact-client-add'}"
            >
                Nouvelle Facture
            </b-button>
            </b-form-group>
            </b-col>
            <b-col md="3" ></b-col>
            <b-col md="6">
            <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Chercher </label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Chercher"
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
            </b-col>
        </b-row>
        </div>
       
               <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        responsive
        :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        <template
            slot="table-row"
            slot-scope="props"
        >

        <div
          v-if="props.column.field === 'remise'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.TotRemise }} %</span>
        </div>
         <div v-else-if="props.column.field === 'NumFact'">
             <b-avatar
          :id="`invoice-row-${props.row.id}`"
          size="32"
          :variant="`light-${getStatuDevis(props.row.paid).variant}`"
        >
          <feather-icon
            :icon="getStatuDevis(props.row.paid).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${props.row.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ props.row.NumFact }} <template v-if="props.row.paid==true">est fermée</template>
          </p>
          <p class="mb-0" v-if='props.row.paid == true' >
            Facture Payée
          </p>
           <p class="mb-0" v-else-if='props.row.paid == false' >
            Facture non payée
          </p>
          <p class="mb-0" v-if='props.row.paid == true' >
            La Date: {{ getDate(props.row.DateFact) }}
          </p>
        </b-tooltip>
          <span class="text-nowrap">{{ props.row.NumFact }} </span>  
       
   
        </div>
        <div
          v-else-if="props.column.field === 'TotalHT'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ TwoDigits(props.row.TotHT) }} Dhs</span>
        </div>
        <div
          v-else-if="props.column.field === 'TotalNet'"
          class="text-nowrap"
        >
          <span class="text-nowrap"><b>{{ TwoDigits(props.row.TotNet) }} Dhs</b></span>
        </div>
        <span v-else-if="props.column.field === 'direct'">
            <b-badge :variant="statusVariant(props.row.direct)">
              <template v-if="props.row.direct === 1">Direct</template>
              <template v-else>Indirecte</template>
            </b-badge>
          </span>
         
         <span v-else-if="props.column.field === 'paid'">
            <b-badge :variant="statusVariant(props.row.paid)">
              <template v-if="props.row.paid==1">Payée</template> 
               <template v-else>Non Payée</template>
          </b-badge>
            <!-- <b-form-checkbox
               :checked="props.row.paid"
                class="custom-control-success"
                name="check-button"
                switch
                disabled
                />-->
            </span> 
            <span v-else-if="props.column.field === 'livred'">
              <b-badge :variant="statusVariant(props.row.livred)">
                <template v-if="props.row.livred==1">Livrée</template> 
                <template v-else>Pas encore</template>
              </b-badge>

            </span>
            <span v-else-if="props.column.field === 'reglement'">
               <span class="text-nowrap" v-if="props.row.reglement!=null">{{props.row.reglement}}</span>
               <span class="text-nowrap" v-else>Pas encore</span>
            </span>
            <!-- fact -->
            <span v-else-if="props.column.field === 'DateFact'">
                {{getDate(props.row.DateFact)}}
            </span>
            <span v-else-if="props.column.field === 'nb_bl'">
                {{props.row.nb_bl}} Bons
            </span>
           <span v-else-if="props.column.field === 'action'">
                 <span>
                <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                >
                <template v-slot:button-content>
                    <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                    />
                </template>
                <b-dropdown-item @click="$router.push({ name: 'fact-client-preview', params: { id: props.row.FactId ,clientId:props.row.clientId}})"  >
                    <feather-icon
                icon="FileIcon"
                size="16"
                class="mr-50"
                
            />
            <span>Télécharger</span>
            
                </b-dropdown-item>
                <!-- @click="$router.push({ name: 'fact-client-update', params: { id: props.row.id }})" @click="ShowFacture(props.row)" -->
                <b-dropdown-item  v-if="props.row.paid!=='true'"
                @click="$router.push({ name: 'fact-client-update', params: { id: props.row.FactId }})"
                 >
                    <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                    />
                    <span>Modifie</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="props.row.paid!=='true'"
                  @click="DeleteFact(props.row)">
                    <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                    />
                    <span>Supprimer</span>
                </b-dropdown-item>
                </b-dropdown>
            </span>
            <!-- <b-row>  <b-button 
                        variant="gradient-success"
                        class="btn-icon"
                         @click="$router.push({ name: 'devis-update', params: { id: props.row.id }})"
                        >
                        <feather-icon icon="Edit2Icon" />
                        
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon"
                         @click="DeleteFact(props.row.id)"
                        >
                        <feather-icon icon="TrashIcon" />
                        
                    </b-button>
                     <b-button
                        variant="gradient-info"
                        class="btn-icon"
                         @click="DeleteFact(props.row.id)"
                        >
                        <feather-icon icon="EyeIcon" />
                        
                    </b-button>
                    
                    </b-row> -->
                    
                    
           
            </span>

            <!-- Column: Common -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Showing 1 to
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>

    </b-card>
  
  <b-sidebar 
    id="add-user-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
           Transformer ce BL en Facture
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
  
       <b-form-group style="margin-bottom: 2rem;"
              label="Reglement"
            >
          
          <v-select 
            v-model='form.reglement'
            label="title"
            :options="optionRoles"
          />
       
            </b-form-group>
            <b-form-group
              label="Payée"
              label-for="paid"
            >
            <b-form-checkbox
                v-model="form.paid"
                class="custom-control-info"
                name="check-button"
                switch
                />
            </b-form-group>
             <b-form-group
              label="Livrée au client"
              label-for="livred"
            >
            <b-form-checkbox
                class="custom-control-warning"
                name="check-button"
                switch
                v-model="form.livred"
                />
            </b-form-group>
             <b-form-group
              label="Note :"
              label-for="livred"
            >
            <b-form-textarea

                    placeholder="Note Facture"
                    rows="2"
                    max-rows="8"
                    no-auto-shrink
                    v-model="form.note"
                />
            </b-form-group>
            <b-form-group
              label="Les pièces jointes"
              label-for="livred"
            >
                <input type="file"  accept="application/pdf,image/*" class="hidden" ref="updateImgInput" multiple="multiple" @change="imageSelected" >
                <b-button style="margin-bottom: 20px;"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                @click="$refs.updateImgInput.click()">
                Importer Pièces Jointes
                </b-button>
            </b-form-group>
            <b-form-group>
                <b-media-aside>
                    <div v-for="(attachment, index) in attachments " :key="index">
                        <span class="label label-primary">{{ attachment.name + ' (' + Number((attachment.size / 1024 / 1024).toFixed(1)) + 'MB)'}}<br></span>
                    </div>
                </b-media-aside>
            </b-form-group>

                    <!--  -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="TransformFact()"
          >
            Sauvegarder
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</div>
<div v-else>
  
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,BRow, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, 
  BDropdownItem,BTooltip,BButton,VBToggle,BCollapse,BSidebar,BForm, BFormCheckbox,BMediaAside,BFormTextarea,
  

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
//  import SidebarAddProduct from './SidebarAddProduct.vue'
import vSelect from 'vue-select'
import NotAuthoriz from '@/views/admin/NotAuthoriz.vue'
// src\views\admin\Clients\Factures\FactureClient.vue 

export default {
      directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
      BCard,BCollapse,
    BCardCode,BRow, BCol,
    VueGoodTable,
    BAvatar,vSelect,BSidebar,BForm,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BButton,BFormCheckbox,BMediaAside,BFormTextarea,
    NotAuthoriz
    // SidebarAddProduct,
  },
  data() {
    return {
      perms:[],
      loading: false,
      showSide:true,
      pageLength: 10,
      dir: false,
      UserID:{},
      columns: [
      {
        label: 'N° Facture',
        field: 'NumFact',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher ',
        },
      },
      {
        label: 'Direct',
        field: 'direct',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher ',
        },
      },
      {
        label: 'Nom Client',
        field: 'NomSociete',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher ',
        },
      },
      
      {
        label: 'Total H.T',
        field: 'TotalHT',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher..',
        },
      },
      {
        label: 'Total Remise %',
        field: 'remise',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher..',
        },
      },
      {
        label: 'Total NET',
        field: 'TotalNet',
        filterOptions: {
          enabled: true,
          placeholder: 'Chercher..',
        },
      },
      {
        label: 'Date',
        field: 'DateFact',
        filterOptions: {
          enabled: true,
          placeholder: 'Date .. ',
        },
      },
      {
        label: 'Reglement',
        field: 'reglement',
        filterOptions: {
          enabled: true,
          placeholder: 'Reglement .. ',
        },
      },
      {
        label: 'Payée',
        field: 'paid',
        filterOptions: {
          enabled: true,
          placeholder: 'Payée .. ',
        },
      },
      {
        label: 'Livrée',
        field: 'livred',
        filterOptions: {
          enabled: true,
          placeholder: 'Livrée .. ',
        },
      },
      {
        label: 'Bons',
        field: 'nb_bl',
        filterOptions: {
          enabled: true,
          placeholder: 'Bons livraison .. ',
        },
      },
      
      
      {
        label: 'Action',
        field: 'action',
      },
      ],
      rows: [],
      searchTerm: '',
      optionRoles: [{ title: 'Pas Encore',value:''},{ title: 'Espèce',value:'Espèce'}, { title: 'Cheque' ,value:'Cheque'}],
      attachments:[],
      form :new Form({    
        id:0,
        reglement:'',
        paid:0,
        livred:0,
        }),
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        cheque : 'light-success',
         1 : 'light-success',
         0     : 'light-warning',
        ESPECE     : 'light-warning',
        CHEQUE : 'light-success',
        espece     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.perms=JSON.parse(localStorage.getItem('access'));
    this.getFact();
    this.UserID=JSON.parse(localStorage.getItem('userData'))
  },
  methods:{
    getFact(){
      this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/getFactClients`).then(res => { this.rows = res.data,this.loading = false })
      },
    getDate(value){
      return  moment(String(value)).format('DD/MM/YYYY');
    },
    TwoDigits(val){
            return parseFloat(val).toFixed(2);
    },
    DeleteFact(row){
      let data= new FormData;
      data.append('UserID', this.UserID.id);
      // console.log(row);
      if(row.paid==1 || row.livred==1){
         this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Vous pouvez pas supprimer les factures payées ou fournis'
              });
      }else{
        this.$swal.fire({
          text: "Vous voulez supprimer cette facture ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer!'
          }).then((result) => {
          if (result.value) {
                this.$http.post(`${config.API_BASE_URL}/api/auth/DeleteFactClient/${row.id}`,data)
                  .then(res=> {
                      if(res.data=='Success'){
                      this.$swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'La facture est bien supprimé',
                      showConfirmButton: false,
                      timer: 1500  })   
                      this.getFact(); 
                      }else if(res.data=='error'){
                          this.$swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Vous pouvez pas supprimer cette facture'
                          });
                      }    
                  }).catch(() => {
                      this.$swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Something went wrong! Pleaz try again'
                          });
                  })
              }             
          })
      }
 
    },
    showBL(row){
        this.form.reset();
        this.form.id=row.id;
    },
    imageSelected(e){
        let selectedFiles=e.target.files;
            if(!selectedFiles.length){
                return false;
            }
            for(let i=0;i<selectedFiles.length;i++){
                this.attachments.push(selectedFiles[i]);
            }
            // console.log(this.attachments);
    },
    TransformFact(){
        this.$swal.fire({
        text: "Vous voulez vraiment transformer au Bon de livraison?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, transformer'
        }).then((result) => {
         if (result.value) {
        let data= new FormData;
          // data.append('UserID', this.UserID.id);
          data.append('reglement', this.form.reglement.value);
          data.append('paid', this.form.paid);
          data.append('livred', this.form.livred);
          data.append('id', this.form.id);
          data.append('note', this.form.note);
           for(let i=0; i<this.attachments.length;i++){
                    data.append('attachments[]',this.attachments[i]);
                }
          // console.log(Object.fromEntries(data))
             this.$http.post(`${config.API_BASE_URL}/api/auth/BLToFact/${this.form.id}`,data)
                .then(res=> {
                        // console.log(res.data)
                     if(res.data=='Success'){
                          document.getElementById("formdata").reset();
                    this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Ce bon de livraison est bien transformé en facture',
                    showConfirmButton: false,
                    timer: 1500  })   
                    this.getFact(); 
                    }else{
                        this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `Erreur de transformation ! Merci d'essayer encore.`
                        });
                    }     
                }).catch(() => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Pleaz try again'
                        });
                }) 
            }             
        })
    },
    /* TransformDevis(id){
      
        this.$swal.fire({
        text: "Vous voulez vraiment transformer en Devis?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, transformer'
        }).then((result) => {
         if (result.value) {
            this.$http.post(`${config.API_BASE_URL}/api/auth/BLToDevis/${id}`)
                .then(res=> {
                    if(res.data=='Success'){
                    this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Le devis est bien transformé en BL',
                    showConfirmButton: false,
                    timer: 1500  })   
                    this.getFact(); 
                    }else if(res.data=='exist'){
                        this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `Ce bon de livraison est déjà transformer en facture.`
                        });
                    }
                    else{
                        this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `Erreur de transformation ! Merci d'essayer encore.`
                        });
                    }    
                }).catch(() => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Pleaz try again'
                        });
                })
            }             
        })
    }, */
     getStatuDevis(status) {
    if (status == true) return { variant: 'success', icon: 'DollarSignIcon' }
    if (status == false) return { variant: 'danger', icon: 'DollarSignIcon' }


    return { variant: 'secondary', icon: 'XIcon' }
  },
  ShowFacture(row){
    console.log(row)
  }
  },
 
}
</script>
<style scoped>
.media-aside.align-self-start {
    display: grid;
}
.form-group {
    margin-bottom: 2rem;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
